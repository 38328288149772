import * as React from "react";
export const ExternalLinksPopout = (
  props: JSX.IntrinsicAttributes & React.ClassAttributes<HTMLDivElement> & React.HTMLAttributes<HTMLDivElement>
) => {
  const ref = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    const anchors = ref.current?.querySelectorAll('a[href*="://"]') ?? new NodeList();
    // @ts-ignore
    // eslint-disable-next-line functional/no-loop-statement
    for (const anchor of anchors) {
      anchor.setAttribute("target", "_blank");
    }
  });
  return <div ref={ref} {...props} />;
};
