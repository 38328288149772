import * as React from "react";
import "@/styles/layout.css";
import { graphql } from "gatsby";
import { UnpublishedProvider, useBadUrls } from "@/context/Unpublished";
import { useSmoothScroll } from "@/hooks/useSmoothScroll";
import { useLandingNavigateTo } from "@/lib/navigateTo";

import { heroAtom, seoAtom, invertedHeroAtom } from "@/store";
import { Indicator } from "@components/Collapsible";
import { Layout } from "@components/Layout";
import { Container as MainContainer } from "@components/Layout/Container";
import { Container } from "@components/Container";
import { Header } from "@components/Header";
import { Title } from "@components/Header/Title";
import { BreadCrumbs } from "@components/Breadcrumbs";
import { DynamicComponent } from "@components/DynamicComponent";
import { ExternalLinksPopout } from "@components/ExternalLinksPopout";
import { RenderMultiple } from "@components/RenderMultiple";
import { Collapsible, Button } from "@components/Collapsible";
import { Button as GlobalButton } from "@components/Button";
import * as RootToggle from "@/hooks/useRootToggle";

import { paramCase } from "change-case";
import { Helmet } from "react-helmet";
import { useHydrateAtoms } from "jotai/utils";
import { EmailListForm } from "../components/EmailListForm";

const Toolkit = (props) => {
  // const children = props.children.map((x) => x._rawBody);
  return (
    <Collapsible
      title=""
      _key={props._rawBody[0]._key}
      expander={() => (
        <Button className="btn btn--action  flex items-center font-lg">
          <DynamicComponent blocks={props._rawBody} />
        </Button>
      )}
      show={false}
    >
      <RenderMultiple of={DynamicComponent} fromPropsList={props.children.map((x) => ({ blocks: x._rawBody }))} />
    </Collapsible>
  );
};

type ToolKitProps = any;
const ToolKitGroup = (props: ToolKitProps) => {
  return (
    <li>
      <h3 id={paramCase(props.fullTitle)}>{props.fullTitle}</h3>
      <ul>
        <RenderMultiple of={Toolkit} fromPropsList={props.toolkits} />
      </ul>
    </li>
  );
};

const ToggleButton = () => {
  const [show] = RootToggle.useToggleValue();
  const onClick = RootToggle.useRootToggle();

  return <GlobalButton onClick={onClick} variant="action">
    <Indicator open={show} />
    {show ? "Collapse" : "Expand"} all toolkits
  </GlobalButton>
}

type ToolkitTemplateProps = any;

const ToolkitTemplate = ({ data }: ToolkitTemplateProps) => {
  const [badUrls] = useBadUrls(data);
  const { sanityToolkitDocument } = data || {};
  // const [sourceRef, reflectiveRef] = useSidebarHighlight();
  useSmoothScroll();
  useLandingNavigateTo();
  const hero =
    data?.sanityToolkitDocument?.hero?.asset?.gatsbyImageData ?? data?.sanityToolkitDocument?.hero
      ? {
        asset: data?.sanityToolkitDocument?.hero._rawAsset,
        crop: data?.sanityToolkitDocument?.hero._rawCrop,
        hotspot: data?.sanityToolkitDocument?.hero._rawHotspot,
      }
      : data?.hero;

  useHydrateAtoms([
    [invertedHeroAtom, true],
    [heroAtom, hero],
    // [heroAtom, data?.sanityToolkitDocument?.hero ?? data?.hero],
    [seoAtom, data?.sanityToolkitDocument?.seo],
  ])
  return (
    <>
      <Helmet>
        <title> {data.sanityToolkitDocument.seo.title || "Toolkits for Departements Under Threat"} | EP3 Guide</title>
      </Helmet>
      <Layout>
        <MainContainer>
          <Header>
            <BreadCrumbs paths={[{ url: "/", label: "Home" }]} title={"Toolkit"} />
            <Title>Toolkits for Departments Under Threat</Title>
          </Header>
          <UnpublishedProvider value={badUrls}>
            <ExternalLinksPopout>
              <Container>
                <RootToggle.Provider>
                  <article className={"tool-kit__body"}>
                    <DynamicComponent blocks={sanityToolkitDocument?._rawDescription} />

                    <h1 className="font-bold text-6xl md:text-6xl text-quaternary ">{sanityToolkitDocument?.heading}</h1>

                    <div className="flex mt-8 gap-8">
                      <ToggleButton />
                    </div>
                    <ol className="list-none">
                      <RenderMultiple of={ToolKitGroup} fromPropsList={sanityToolkitDocument?.toolkits} />
                    </ol>
                  </article>
                </RootToggle.Provider>
              </Container>
            </ExternalLinksPopout>
          </UnpublishedProvider>
          {sanityToolkitDocument?.includeEmailForm && (

            <div className="grid items-center justify-center w-full p-5 mt-4">
              <EmailListForm />
            </div>
          )}
        </MainContainer>
      </Layout>
    </>
  );
};

export const pageQuery = graphql`
  query ($slug: String!) {
    hero: file(relativePath: { eq: "hero-random-page.jpg" }) {
      ...heroImageFragment
    }

    sanityToolkitDocument: sanityToolkitDocument(slug: { current: { eq: $slug } }) {
      heading
      includeEmailForm
      seo {
        description
        title
      }
      hero {
        asset {
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
        }
        _rawAsset(resolveReferences: { maxDepth: 10 })
        _rawCrop(resolveReferences: { maxDepth: 10 })
        _rawHotspot(resolveReferences: { maxDepth: 10 })
      }

      slug {
        current
      }
      tags {
        term
      }
      _rawDescription(resolveReferences: { maxDepth: 5 })
      lastUpdatedDate
      versionNumber
      toolkits {
        title
        heading
        fullTitle
        toolkits {
          _rawBody(resolveReferences: { maxDepth: 5 })
          children {
            _rawBody(resolveReferences: { maxDepth: 5 })
            children {
              _rawBody(resolveReferences: { maxDepth: 5 })
              children {
                _rawBody(resolveReferences: { maxDepth: 5 })
                children {
                  _rawBody(resolveReferences: { maxDepth: 5 })
                }
              }
            }
          }
        }
      }
    }

    allNonVersionedDocuments: allSanityEp3Document(filter: { publicationStatus: { ne: "live" } }) {
      nodes {
        slug {
          current
        }
        publicationStatus
      }
    }

    allNonVersionedPages: allSanityPage(filter: { publicationStatus: { ne: "live" } }) {
      nodes {
        slug {
          current
        }
        publicationStatus
      }
    }
  }
`;
export default ToolkitTemplate;
