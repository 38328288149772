import * as React from "react";

export type ContainerProps = {
  children: React.ReactNode;
  className?: string;
  stretch?: boolean;
  text?: boolean;
};

export const Container = ({ children, className = "", text }: ContainerProps) => {
  const classNames = `py-4 px-4 container mx-auto ${className} `;
  return (
    <div className={classNames} style={{ maxWidth: text ? "120ch" : "auto", overflowWrap: "anywhere" }}>
      {children}
    </div>
  );
};
