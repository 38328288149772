import * as React from "react";

const isCompletelyExpanded = (root?: HTMLDivElement) => {
  if (!root) {
    return false;
  }
  const buttons = Array.from(root.querySelectorAll(".collapse-expander button[aria-expanded]:first-of-type"));
  const expandedButtons = buttons.filter((button) => button.getAttribute("aria-expanded") === "true");
  return expandedButtons.length === buttons.length ? true : false;
}

type ToggleContextType = [boolean, (...args: any[]) => unknown, React.RefObject<HTMLDivElement>]
const context = React.createContext<ToggleContextType>([false, () => { }, React.createRef()]);
export const useToggleValue = () => {
  const [value, setValue, reference] = React.useContext<ToggleContextType>(context);

  const updateValue = React.useCallback(() => {
    setTimeout(() => {
      setValue(() => {
        if (!reference.current) {
          return value;
        }
        return isCompletelyExpanded(reference?.current);
      })
    }, 100)
  }, [reference?.current])

  return [value as boolean, updateValue];
};

const toggleContext = React.createContext<() => unknown>(() => { });
export const useRootToggle = () => React.useContext(toggleContext);

export const Provider = (props?: {
  show?: boolean;
  className?: string;
  children?: any;
}) => {
  const [show, setShow] = React.useState<boolean>(props?.show ?? false);
  const reference = React.useRef<HTMLDivElement>(null);

  const onToggle = React.useCallback(() => {
    if (!reference.current) {
      return;
    }

    reference.current?.querySelectorAll(".collapse-expander button[aria-expanded]:first-of-type").forEach((el: any) => {
      if (el.getAttribute("aria-expanded") === show.toString()) {
        el.click();
        return;
      }
    });
    setTimeout(() => {
      setShow((value) => reference?.current ? isCompletelyExpanded(reference.current) : value)
    }, 100)
  }, [show]);

  React.useEffect(() => {
    setTimeout(() => {
      setShow((value) => reference?.current ? isCompletelyExpanded(reference.current) : value)
    }, 1000)
  }, [reference?.current])

  return (
    <context.Provider value={[show, setShow, reference]}>
      <toggleContext.Provider value={onToggle}>
        <div ref={reference} className={props?.className ?? ""}>
          {props?.children}
        </div>
      </toggleContext.Provider>
    </context.Provider>
  );
}

